// user play sessionjo
export const MAX_WIDTH = 760;
export const MIN_ANSWER_LENGTH_SOFT = 28; // where we warn users
export const MIN_ANSWER_LENGTH_HARD = 12; // where we won't record or embed
export const DEFAULT_PLAYBACK_RATE = 1.4; // it's x10 to deal with a edge case bug

export const LIBRARY_PATHS_KEY = 'libraryPaths';

// for local storage
export const RECENT_USER_ACTIVITY_KEY = {
  visit: 'recentUserVisit',
  completion: 'recentUserCompletion',
};

export const CRONE_TAGLINE = 'Feel smarter';
export const CRONE_DESCRIPTION = 'Read books as bite-sized games';
export const CRONE_TAG_COMBINED =
  'The free, fun, immersive way to read great books';

export const CRONE_URI = process.env.CRONE_URI || 'https://www.crone.ai';
// difference from one above is that APP_URI points to localhost in dev
export const APP_URI =
  process.env.NEXT_PUBLIC_APP_URI ||
  process.env.EXPO_PUBLIC_APP_URI ||
  process.env.APP_URI ||
  'https://www.crone.ai';

export const MIN_WRITING_SCORE = 5;

// TODO: shorten when we have more traffic
export const CACHE_TTL = 3600 * 24; // 1 day

export const APP_STORE_LINK = 'https://apps.apple.com/app/id6476424527';
export const LOGGEDIN_PATH = '/home';
export const SIGNUP_PATH = '/signup';
export const SIGNIN_PATH = '/login';
// export const TRIAL_EPISODE_ID =
//   process.env.NODE_ENV === 'development' ? 106 : 325;

// export const TRIAL_EPISODE_PATH = `/start/cc/${TRIAL_EPISODE_ID}`;

export const DISCORD_LINK = 'https://discord.gg/RcVCHnnK';

export const TEST_EMAIL = 'hi+test@crone.ai';
export const TEST_PUSH_TOKEN =
  process.env.NODE_ENV === 'development'
    ? 'ExponentPushToken[KpLI3RIrePf6SSHzOw4z0e]' // kyle@hakeema.com in dev
    : process.env.TEST_PUSH_TOKEN || '';

export const CC_BASE = {
  url: `${APP_URI}/start/cc/`,
  path: '/start/cc/',
};

export const PATH_BASE = {
  url: `${APP_URI}/paths/`,
  path: '/paths/',
};

export const LEXICON_WIKI_BASE = {
  url: `${APP_URI}/wiki/`,
  path: '/wiki/',
};
